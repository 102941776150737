import { createSlice } from "@reduxjs/toolkit";

const viewDressSlice = createSlice({
  name: "viewDress",
  initialState: {
    viewDressArray: [],
  },
  reducers: {
    pushDress: (state, action) => {
      state.viewDressArray = [action.payload];
    },
  },
});

export const { pushDress } = viewDressSlice.actions;

export default viewDressSlice.reducer;
