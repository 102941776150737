import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import whatsappLogo from "../Images/whatsapplogo.png";

const CartPayment = () => {
  const cartTotalAmount = useSelector((state) => state.cart.cartTotalAmount);

  return (
    <div className="home">
      <h2 className="home-heading">Payment Details</h2>
      <br />
      <div>
        Your total amount to pay: <strong>N{cartTotalAmount}</strong>
      </div>
      <div>Please make a bank transfer to the following bank account:</div>
      <div>
        Account Number: <strong>1019805635</strong>
      </div>
      <div>
        Bank: <strong>UBA (United Bank for Africa)</strong>
      </div>
      <div>
        Account Name: <strong>Feminine Styles</strong>
      </div>
      <br />
      <div>
        <strong>
          Once your payment is successful, contact us to verify your payment and
          process your order.
        </strong>
      </div>
      <Link to="https://wa.me/2349072637906" className="whatsapp-link">
        <img src={whatsappLogo} className="whatsapp-logo" alt="Whatsapp Link" />
      </Link>
      <br />
      <Link to="/cart">Back to Cart</Link>
    </div>
  );
};
export default CartPayment;
