import { configureStore } from "@reduxjs/toolkit";

import productsReducer from "./features/productsSlice";
import authReducer from "./features/authSlice";
import cartReducer from "./features/cartSlice";
import adminReducer from "./features/adminAuthSlice";
import searchReducer from "./features/searchProductSlice";
import viewDressReducer from "./features/viewDressSlice";

const store = configureStore({
  reducer: {
    products: productsReducer,
    auth: authReducer,
    cart: cartReducer,
    adminLogin: adminReducer,
    search: searchReducer,
    viewDress: viewDressReducer,
  },
});

export default store;
