import React, { useEffect, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/adminAuthSlice";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (!adminLoggedIn || !adminToken) {
      navigate("/admin-login");
    } else {
      setAuthenticated(true);
    }
  }, [adminLoggedIn, adminToken, navigate]);

  return (
    authenticated && (
      <div className="admin">
        <div className="quick-links">
          <NavLink to="/admin" className="navlink">
            <div className="dashboard">Dashboard</div>
          </NavLink>
          <div className="dash-menu">
            <NavLink to="/admin/products" className="dashboard-list">
              Products
            </NavLink>
            <NavLink to="/admin/users" className="dashboard-list">
              Users
            </NavLink>
            <NavLink to="/admin/payments" className="dashboard-list">
              Orders
            </NavLink>
          </div>
        </div>
        <div className="outlet-div">
          <Outlet className="outlet" />
          <div className="welcome-container">
            <h2 className="welcome-message">Welcome back admin!</h2>
            <p className="instruction">Tap any of the buttons to start.</p>
            <button
              className="logout-button"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Admin;
