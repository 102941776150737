import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";

const ViewDress = () => {
  const viewDressArray = useSelector((state) => state.viewDress.viewDressArray);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (viewDressArray.length === 0) {
      navigate("/");
    }
  }, [viewDressArray, navigate]);

  return (
    viewDressArray[0] && (
      <div>
        <div className="view-dress-div">
          <div className="add-div">
            <div
              className="view-dress-add"
              onClick={() => {
                dispatch(addToCart(viewDressArray[0]));
                navigate("/cart");
              }}
            >
              Add To Cart
            </div>
          </div>
          <img src={viewDressArray[0].image} alt={viewDressArray[0].name} />
        </div>

        <div className="back-to-home-div" onClick={() => navigate("/")}>
          <div className="back-to-home">back to Home</div>
        </div>

        <div
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "5px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {viewDressArray[0].name}
          </div>
          <div
            style={{ marginBottom: "5px", display: "flex", flexWrap: "wrap" }}
          >
            {viewDressArray[0].desc}
          </div>
          <div
            style={{
              marginBottom: "5px",
              display: "flex",
              flexWrap: "wrap",
              fontWeight: "bold",
            }}
          >
            N{viewDressArray[0].price.toLocaleString()}
          </div>
        </div>
      </div>
    )
  );
};

export default ViewDress;
