import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { registerUser } from "../../features/authSlice";

const RegistrationForm = () => {
  const registerSuccess = useSelector((state) => state.auth.registerSuccess);
  const registerError = useSelector((state) => state.auth.registerError);
  const registerLoading = useSelector((state) => state.auth.registerLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [different, setDifferent] = useState(false);

  const userData = {
    username,
    email,
    password,
    phoneNumber,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setDifferent(true);
    } else {
      dispatch(registerUser(userData));
    }
  };

  useEffect(() => {
    if (registerSuccess === true) {
      navigate("/cart");
    }
  }, [registerSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div htmlFor="username" className="login-tag">
          Username:
        </div>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div>
        <div htmlFor="email" className="login-tag">
          Email:
        </div>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <div htmlFor="phoneNumber" className="login-tag">
          Phone Number:
        </div>
        <input
          type="tel"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div>
      <div>
        <div htmlFor="password" className="login-tag">
          Password:
        </div>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div>
        <div htmlFor="confirmPassword" className="login-tag">
          Confirm Password:
        </div>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        disabled={registerLoading === true}
        className="user-login-btn"
      >
        {registerLoading === true ? "Loading..." : "Register"}
      </button>
      {registerError && <div className="register-error">{registerError}</div>}
      {different && (
        <div className="different-error">Passwords do not match!</div>
      )}
    </form>
  );
};

export default RegistrationForm;
