import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Payment() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (!adminLoggedIn || !adminToken) {
      navigate("/admin-login");
    } else {
      setAuthenticated(true);
      async function fetchPayments() {
        try {
          const response = await fetch(
            "https://femininestyles-api2.onrender.com/api/all-pay-ments/"
          );
          const data = await response.json();
          setPayments(data);
          setLoading(false);
        } catch (err) {
          console.error(err.message);
        }
      }

      fetchPayments();
    }
  }, [adminLoggedIn, adminToken, navigate]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    authenticated && (
      <div className="users-container">
        <h2 className="customer-info">All Orders</h2>
        {payments.length === 0 ? (
          <div className="no-users">No orders found.</div>
        ) : (
          <ul className="user-list">
            {payments.map((payment) => (
              <li key={payment._id} className="user-row">
                <h2 className="user-info">N{payment.amount}</h2>
                <p className="user-info">{payment.customer.email}</p>
                <p className="user-info">{payment.customer.phone}</p>
                <p className="user-info">Date/time: {payment.date}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  );
}

export default Payment;
