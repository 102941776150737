import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { loginUser } from "../../features/authSlice";

const LoginForm = () => {
  const token = useSelector((state) => state.auth.token);
  const loginError = useSelector((state) => state.auth.loginError);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userCheck = {
    email,
    password,
  };

  useEffect(() => {
    if (token) {
      navigate("/cart");
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setLoading(true);
    await dispatch(loginUser(userCheck));
    await setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="login-tag">Email:</div>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="login-tag">Password:</div>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit" className="user-login-btn" disabled={loading}>
        {loading ? "Loading..." : "Log In"}
      </button>
      {loginError && <div>{loginError}</div>}
      <div className="register-link">
        <span>No account? </span>
        <Link to="/register">Register here</Link>
      </div>
    </form>
  );
};

export default LoginForm;
