import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../../features/adminAuthSlice";

const AdminLogin = () => {
  const [adminCheck, setAdminCheck] = useState({ username: "", password: "" });
  const loginError = useSelector((state) => state.adminLogin.error);
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (adminLoggedIn && adminToken) {
      navigate("/admin");
    }
  }, [adminLoggedIn, adminToken, navigate]);

  const handleSignIn = async () => {
    setLoading(true); // Set loading state to true

    try {
      await dispatch(adminLogin(adminCheck));
      setLoading(false); // Set loading state to false after the request is complete
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading state to false if an error occurs
    }
  };

  return (
    <div>
      <div className="loginpage-header">Admin Login</div>
      {loginError && <p>{loginError}</p>}
      <input
        type="text"
        placeholder="Username"
        value={adminCheck.username}
        onChange={(e) =>
          setAdminCheck({ ...adminCheck, username: e.target.value })
        }
      />
      <input
        type="password"
        placeholder="Password"
        value={adminCheck.password}
        onChange={(e) =>
          setAdminCheck({ ...adminCheck, password: e.target.value })
        }
      />
      <button onClick={handleSignIn} className="admin-login-btn">
        {loading ? "Loading..." : "Login"}
      </button>
    </div>
  );
};

export default AdminLogin;
