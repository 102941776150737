import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addToCart,
  clearCart,
  getTotals,
  removeFromCart,
  removeProduct,
} from "../features/cartSlice";
import { extractUserFromToken } from "../features/authSlice";
import axios from "axios";
import { Link } from "react-router-dom";

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const { email, phoneNumber, username } = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);
  const cartTotalAmount = useSelector((state) => state.cart.cartTotalAmount);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTotals());
    dispatch(extractUserFromToken());
  }, [cartItems, dispatch]);

  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    setLoading(true); // Set loading state to true

    const paymentData = {
      user_amount: cartTotalAmount,
      user_email: email,
      user_phoneNumber: phoneNumber,
      user_name: username,
      user_cartItems: cartItems,
    };

    try {
      await axios.post(
        "https://femininestyles-api2.onrender.com/api/VeG6jK3u1fTqYnBm/payment",
        paymentData
      );
      console.log("posted user info");

      // navigate to the cart payment page
      navigate("/cart-payment");

      setLoading(false); // Set loading state to false after the request is complete
    } catch (error) {
      console.error("Error during checkout:", error);
      setLoading(false); // Set loading state to false if an error occurs
    }
  };

  return (
    <div className="cart-container">
      <h2>Shopping Cart</h2>
      {cartItems.length === 0 ? (
        <div className="cart-empty">
          <p>Your cart is currently empty</p>
          <div className="start-shopping">
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span>Start Shopping</span>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="titles">
            <h3 className="product-title">Product</h3>
            <h3 className="price">Price</h3>
            <h3 className="quantity">Quantity</h3>
            <h3 className="total">Total</h3>
          </div>
          <div className="cart-items">
            {cartItems.map((currentCartItem) => (
              <div className="cart-item" key={currentCartItem._id}>
                <div className="cart-product">
                  <img src={currentCartItem.image} alt={currentCartItem.name} />
                  <div className="product-desc">
                    <h3>{currentCartItem.name}</h3>
                    <p>{currentCartItem.desc}</p>
                    <button
                      onClick={() => dispatch(removeProduct(currentCartItem))}
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="cart-product-price">
                  N{currentCartItem.price.toLocaleString()}
                </div>
                <div className="cart-product-quantity">
                  <button
                    onClick={() => dispatch(removeFromCart(currentCartItem))}
                  >
                    -
                  </button>
                  <div className="count">{currentCartItem.cartQuantity}</div>
                  <button onClick={() => dispatch(addToCart(currentCartItem))}>
                    +
                  </button>
                </div>
                <div className="cart-product-total-price">
                  N
                  {(
                    currentCartItem.price * currentCartItem.cartQuantity
                  ).toLocaleString()}
                </div>
              </div>
            ))}
          </div>
          <div className="cart-summary">
            <button className="clear-btn" onClick={() => dispatch(clearCart())}>
              Clear Cart
            </button>
            <div className="cart-checkout">
              <div className="subtotal">
                <span>Subtotal</span>
                <span className="amount">
                  N{cartTotalAmount.toLocaleString()}
                </span>
              </div>
              {token ? (
                <button className="cart-login" onClick={handleCheckout}>
                  {loading ? "Loading..." : "Check out"}
                </button>
              ) : (
                <button
                  className="cart-login"
                  onClick={() => navigate("/login")}
                >
                  Login to Checkout
                </button>
              )}
              <div className="continue-shopping">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <span>Continue Shopping</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
